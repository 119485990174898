// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"


// gatsby-browser.js
import React from 'react';
import { ModalProvider } from './src/components/modalContext'
import { Helmet } from "react-helmet"

export const wrapRootElement = ({ element }) => {
    return (
        <>
            <Helmet>
                <script>
                    {`(function (w, d, t) {
                        _ml = w._ml || {};
                        _ml.eid = '81430';
                        _ml.cid = '6d5725e8-8b0e-4b7d-8d73-149567972015';
                        var s, cd, tag; s = d.getElementsByTagName(t)[0]; cd = new Date();
                        tag = d.createElement(t); tag.async = 1;
                        tag.src = 'https://ml314.com/tag.aspx?' + cd.getDate() + cd.getMonth();
                        s.parentNode.insertBefore(tag, s);
                    })(window, document, 'script');`}
</script>
            </Helmet>
            <ModalProvider>{element}</ModalProvider>
        </>
    );
}